import { gql } from "graphql-tag";


export const GET_ASSOCIATED_MODULES = gql`
  query SelectAssociatedModules {
    risk_management_risk_associated_modules {
      id
      id_config_associated_modules
      id_risk
    }
  }
`;

export const SAVE_ASSOCIATED_MODULE = gql` 
mutation saveAssociatedModule ($associatedChosen: risk_management_risk_associated_modules_insert_input!){
  insert_risk_management_risk_associated_modules_one(object: $associatedChosen ) {
    id
    id_config_associated_modules
    id_risk
    config_associated_module {
      tenant_module {
        module {
          module
        }
      }
    }
  }
}
`;


export const UPDATE_ASSOCIATED_MODULE_CONFIG = gql`
  mutation UpdateAssociatedModule($id: Int!, $active: Boolean = false) {
    update_risk_management_config_associated_modules_by_pk(pk_columns: {id: $id}, _set: {active: $active}) {
      active
      id
      id_tenant
      id_tenant_module
      tenant_module {
      module {
        id
        module
      }
    }
    }
  }
`;

export const UPDATE_ASSOCIATED_MODULE = gql`
  mutation UpdateAssociatedModule($id: Int!, $id_config_associated_modules: Int!, $id_risk: Int!) {
    update_risk_management_risk_associated_modules_by_pk(
      pk_columns: { id: $id },
      _set: { id_config_associated_modules: $id_config_associated_modules, id_risk: $id_risk }
    ) {
      id
      id_config_associated_modules
      id_risk
    }
  }
`;


export const GET_MODULES = gql`
query modules {
  risk_management_config_associated_modules {
    id_tenant
    id_tenant_module
    tenant_module {
      module {
        module
        id
      }
    }
    active
    id
  }
}
`

export const GET_MODULES_TRUS_TECH = gql`
 query getModules {
  tenant_module {
    id_tenant
    module {
      id
      module
      tenant_modules {
        id_tenant
        id_module
        id
      }
    }
  }
}
`;

export const INSERT_ASSOCIATED_MODULE_CONFIG = gql`
  mutation insertAssociatedModule($id_tenant_module: Int!, $active: Boolean!) {
    insert_risk_management_config_associated_modules_one(object: { active: $active, id_tenant_module: $id_tenant_module }) {
      active
      id
      id_tenant
      tenant_module {
        id
        id_module
        id_tenant
        module {
          module
          id
        }
      }
    }
  }
`;

export const INSERT_ASSOCIATED_MODULE = gql`
  mutation InsertNewAssociatedModule($objects: [risk_management_risk_associated_modules_insert_input!]!) {
    insert_risk_management_risk_associated_modules(objects: $objects) {
      returning {
        id
        id_config_associated_modules
        id_risk
      }
    }
  }
`;


export const GET_CHOSEN_MODULES = gql`
  subscription getChosenModules ($id_risk: Int!) {
    risk_management_risk_associated_modules(where: {id_risk: {_eq: $id_risk}}) {
    config_associated_module {
      tenant_module {
        module {
          module
        }
      }
    }
  }
  }
`;

