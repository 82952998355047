import iconHomeVue from "@/components/icon/icon-home.vue";
import iconClockVue from "@/components/icon/icon-clock.vue";
import iconMapPinVue from "@/components/icon/icon-map-pin.vue";
import iconListCheckVue from "@/components/icon/icon-list-check.vue";
import IconFlag from "@/components/ui/icon/icon-flag.vue";

export interface HistoryItemProps {
  id: number;
  value: string;
  label: string;
  icon: Function;
  metadata: Record<string, string>;
  completed: boolean;
  description?: string;
  status?: 'ended' | 'repproved' | 'approved' | 'in_progress';
}

export interface LinkedTaskProps {
  id: number;
  title: string;
  responsible: string;
  deadline: string;
  status: 'repproved' | 'approved' | 'in_progress';
  details: string;
  metadata: {
    start_date: string;
    end_date: string;
    completed: boolean;
  };
}

export interface TableItemProps {
  Id: number;
  name: string;
  category: string;
  initial_risks: number;
  state: string;
  description: string;
  attachments: string[];
  tasks: LinkedTaskProps[];
  chat: string[];
  history: HistoryItemProps[];
}

// Função para formatar dados da API
export function formatData(payload: any) {
  return payload.map(item => {
    const residualRisk = item.risk_monitoring?.length > 0 ? item.risk_monitoring[0].residual_risk : null;
    return {
      Id: item.id, 
      name: item.name, 
      category: item.category.value, 
      description: item.description, 
      initial_risk: residualRisk || item.initial_risk, 
      state: item.state, 
      user_appraiser: item.user_appraiser.displayName, 
      id_user_appraiser: item.user_appraiser.id,
      user_responsible: item.user_responsible.displayName, 
      id_user_responsible: item.user_responsible.id,
      created_at: item.created_at,
      attachments: [], 
      tasks: [], 
      chat: [], 
      history: item.log_risks,
    };
  });
}
export const tableCols = [
  { field: "Id", title: "ID", required: true },
  { field: "name", title: "Nome" },
  { field: "category", title: "Categoria" },
  { field: "user_appraiser", title: "Avaliador", customStyles: 'custom-col', formatter: (data) => data?.displayName },
  { field: "description", title: "Descrição" },
  { field: "initial_risk", title: "Risco Inicial" },
  { field: "state", title: "Status" },
];

