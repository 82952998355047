import { gql } from "graphql-tag";


export const SELECT_CATEGORY = gql`
 query getAllCategoryRisks {
  risk_management_combo_data(distinct_on: value, where: { name_combo: { _eq: "risk_cat" }, active: { _eq: true } }) {
    value
    id
    name_combo
    id_tenant
  }
}
`;


export const SELECT_TREATMENT_RESULT = gql`
 query getAllCategoryRisks {
  risk_management_combo_data(distinct_on: value, where: { name_combo: { _eq: "treatment_result" }, active: { _eq: true } }) {
    value
    id
    name_combo
    id_tenant
  }
}
`;



export const SELECT_INITIAL_RISK = gql`
query getAllActionRisks {
  risk_management_risk(distinct_on: initial_risk) {
    initial_risk
    id
    state
  }
}
`

export const SELECT_EVALUATOR = gql`
query user_appraiser {
  risk_management_risk (distinct_on: id_user_appraiser) {
    user_appraiser {
      displayName
      id
    }
  }
}
`

export const SELECT_OWNER = gql`
query user_owner {
  risk_management_risk(distinct_on: id_user_owner) {
    user_owner {
      displayName
      id
    }
  }
}
`

export const SELECT_AREA_NOT_USER = gql`
query SelectUserAreaNot($id_user: uuid!) {
  area(where: {_not: {user_areas: {id_user: {_eq: $id_user}}}, status: {_eq: "A"}}) {
    id
    description
    status
  }
}`;

export const SELECT_RESPONBILITIES = gql`
query CountResponsibilities($id_user: uuid!) {
  divider_appraiser: risk_management_risk_aggregate(where: {id_user_appraiser: {_eq: $id_user}, state: {_nin: ["Descartada"]}}) {
    aggregate {
      count
    }
    nodes{
      id
    }
  }
  divider_responsible: risk_management_risk_aggregate(where: {id_user_responsible: {_eq: $id_user}, state: {_nin: ["Descartada"]}}) {
    aggregate {
      count
    }
    nodes{
      id
    }
  }
  divider_respondent: risk_management_risk_task_aggregate(where: {id_owner_task: {_eq: $id_user}, state: {_nin: ["Descartada", "Finalizada"]}}) {
    aggregate {
      count
    }
    nodes{
      id
      risk {
       id_responsible_area
      }
    }
  }
}
`;

export const SELECT_USERS_BY_ROLE = (id_user: string, roles: string, user_areas: string) =>
gql`query SelectUserByRole($_eq: String! = "risk_management") {
    user_module(order_by: {user: {displayName: asc}}, where: {module: {module: {_eq: $_eq}}, user: {user_module_roles: {role: {_neq: "tenant_admin_rm_bay" ${!!roles ? `, _in: "${roles}"`:''}}, module: {module: {_eq: $_eq}}}, disabled: {_eq: false}${id_user ? `, id: {_neq: "${id_user}"}`: ''} ${user_areas ? `, _and:[ ${user_areas}]`: ''} }, blocked: {_eq: false}}) {
      user {
        id
        displayName
        email
        disabled
        phoneNumber
      }
    }
}`;

export const SELECT_RESPONSIBLE = gql`
query user_responsible {
  risk_management_risk(distinct_on: id_user_responsible) {
    user_responsible {
      displayName
      id
    }
  }
}
`

export const SELECT_AREA = gql`
query area {
  risk_management_risk(order_by: {area: {description: asc}}) {
    area {
      description
      id
    }
  }
}
`

export const TASK_OWNER = gql`
query owner {
  risk_management_risk_task(distinct_on: id_owner_task) {
      owner {
          displayName
          id
        }
  }
}
`

export const MODULES = gql`
query modules {
  risk_management_config_associated_modules {
    id
    tenant_module {
      module {
        module
        id
      }
    }
  }
}
`
export const SELECT_SUPPLIER = gql`
query supplier {
  due_diligence_solicitation(distinct_on: id_supplier) {
    supplier {
      corporate_reason
      id
    }
  }
}
`

export const SELECT_STATUS = gql`
query status {
  due_diligence_solicitation(distinct_on: status) {
    status
  }
}
`

export const SELECT_RISK = gql`
query risk {
  due_diligence_solicitation(distinct_on: risk) {
    risk
  }
}
`

export const SELECT_PARECER = gql`
query parecer {
  due_diligence_solicitation(distinct_on: parecer_due_diligence) {
    parecer_due_diligence
  }
}
`

export const SELECT_TYPOLOGY = gql`
query typology {
  due_diligence_solicitation(distinct_on: id_typology) {
    typology {
      description
      id
    }
  }
}
`

export const SELECT_RISK_LEVEL = gql`
query riskLevel {
  data_mapping_combo_data(where: {name_combo: {_eq: "risk_level"}}, distinct_on: value) {
    name_combo
    value
    id
  }
}
`

export const SELECT_VOLUME_HOLDERS = gql`
query volumeHolders {
  data_mapping_treatment_activity {
    volume_of_holders
  }
}
`

export const SELECT_PROFILE_HOLDERS = gql`
query profileHolders {
  data_mapping_combo_data(where: {name_combo: {_eq: "prof_holders"}}, distinct_on: value) {
    value
    id
    name_combo
  }
}
`
export const SELECT_DATA_TYPES = gql`
query dataTypes {
  data_mapping_combo_data(where: {name_combo: {_eq: "data_types"}}, distinct_on: value) {
    value
    id
    name_combo
  }
}
`
export const SELECT_LOCATION_HOLDERS = gql`
query locationHolders {
  data_mapping_combo_data(where: {name_combo: {_eq: "loc_holders"}}, distinct_on: value) {
    value
    id
    name_combo
  }
}
`





