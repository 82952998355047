import { formatData } from "./../service/table-data";
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { TableItemProps, LinkedTaskProps } from "@/service/table-data";
import { submitFormFilter } from "@/api/ts/dropdowns_config";

export type FormState = Ref<Record<string, string>>;
// this is our 'risco' app store
interface AppStoreProps {
  dataItems: TableItemProps[];
  filteredDataItems: TableItemProps[];
  initialTableCols: Ref<object[]>;
  favoriteItems: TableItemProps[];
  searchInput: Ref<string>;
  sidebarFilterKey: Ref<string>;
  isShowSidebar: Ref<boolean>;
  currentSelectedPlan: Ref<TableItemProps | null>;
  selectedTaskRow: Ref<LinkedTaskProps | null>;
  formRiskState: FormState;
}

export const useAppStore = defineStore("appStore", {
  state: (): AppStoreProps => ({
    dataItems: [],
    filteredDataItems: [],
    initialTableCols: ref([]),
    favoriteItems: [],
    searchInput: ref(""),
    sidebarFilterKey: ref(""),
    isShowSidebar: ref(true),
    currentSelectedPlan: ref(null),
    selectedTaskRow: ref(null),
    formRiskState: ref({
      name: "",
      description: "",
      category: "",
      priority: "",
      evaluator: "",
      area: "",
      responsible: "",
    }) as FormState,
  }),
  actions: {
    setInitialTableItems(payload: any) {
      const dataFormatted = formatData(payload);
      this.filteredDataItems = dataFormatted;
      this.dataItems = dataFormatted;
    },
    validateStatus(payload) {
      const validStatuses = ["identificado", "avaliado", "em tratamento", "tratado", "monitoramento"];
      return validStatuses.includes(payload.toLowerCase());
    },
    setTableFilter(payload: string, key?: "status" | "initial_risk") {
      if (payload === "all") {
        this.filteredDataItems = this.dataItems;
      } else if (key === "status" && this.validateStatus(payload)) {
        this.filteredDataItems = this.dataItems.filter(
          (item) => item.state.toLowerCase() === payload.toLowerCase()
        );
      } else if (key === "initial_risk") { 
        this.filteredDataItems = this.dataItems.filter(
          (item) => item.initial_risk.toLowerCase() === payload.toLowerCase()
        );
      } else if (payload === "favorite") {
        this.filteredDataItems = this.dataItems.filter((item) =>
          this.favoriteItems.some((favorite) => favorite.Id === item.Id)
        );
      }
    },

    setTableCustomFilter(payload: any) {
      this.filterState = payload;
      submitFormFilter(payload)
        .then((filteredData) => {
          this.filteredDataItems = formatData(filteredData);          
        })
        .catch((error) => {
          console.error("Erro ao buscar dados filtrados:", error);
        });
    },
    resetCustomFilter() {
      this.filterState = null;
      this.filteredDataItems = this.dataItems;
    },
    addRowToFavorite(payload: TableItemProps) {
      const alreadyFavorited = this.favoriteItems.some(
        (item) => item.Id === payload.Id
      );
      if (!alreadyFavorited) {
        this.favoriteItems.push(payload);
      }
    },
    removeRowFromFavorite(payload: any) {
      const index = this.favoriteItems.findIndex(
        (item) => item.Id === payload.Id
      );
      if (index !== -1) {
        this.favoriteItems.splice(index, 1);
      }
    },
    setSearchInput(payload: string) {
      this.searchInput = payload;
    },
    setSidebarFilterKey(payload: string) {
      this.sidebarFilterKey = payload;
    },
    toggleSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
    setCurrentSelectedPlan(payload: TableItemProps | null) {
      if (this.dataItems.length === 0) return;
      this.currentSelectedPlan = payload;
    },
    setSelectedTaskRow(payload: LinkedTaskProps | null) {
      this.selectedTaskRow = payload;
    },
  },
});
function formatStatus(status: any) {
  throw new Error("Function not implemented.");
}