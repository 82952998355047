import { gql } from "graphql-tag";

export const SELECT_AREA = gql`
  query SelectArea {
    area {
      id
      description
      status
    }
  }
`;

export const INSERT_AREA = gql`
  mutation InsertNewArea($objects: [area_insert_input!]!) {
    insert_area(objects: $objects) {
      returning {
        id
        description
        status
        id_tenant
      }
    }
  }
`;

export const UPDATE_AREA = gql`
mutation UpdateArea($id: Int!, $status: bpchar!, $blocked: Boolean = false) {
  update_area_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
    description
    status
  }
  update_user_area(where: {id_area: {_eq: $id}}, _set: {blocked: $blocked}) {
    affected_rows
  }
}
`;